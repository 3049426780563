#quickAddPop {
    width: 29.6em;
    .btn-icon {
        i {
            font-size: 3rem;
            line-height: 2rem;
        }
    }

    .makeFavBtn {
        font-size: 1.5rem;
        line-height: 2rem;
        i {
            font-size: 2rem;
            line-height: 2rem;
        }
    }
}

.QuickCard {
    height: 100px;
    padding: 0;
    width: 32%;
    margin-right: 2%;
    margin-top: 2%;
    max-width: 32%;
}
.QuickCard:nth-child(3n + 0) {
    margin-right: 0%;
}

.QuickCard:nth-child(1),
.QuickCard:nth-child(2),
.QuickCard:nth-child(3) {
    margin-top: 0%;
}

.QuickCard a {
    width: 100% !important;
    height: 100%;

    span {
        display: inline-block;
        font-family: Nunito, sans-serif;
        font-size: 0.9rem !important;
        letter-spacing: 0.09px;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        line-height: 1.1rem !important;
    }

    &:hover {
        span {
            overflow-wrap: break-word;
            white-space: normal;
            word-wrap: break-word;
        }
    }
}

.popover .card .quickAddCatTitle.card-title {
    color: #333333;
    font-size: 1rem;
    margin-bottom: 3%;
    text-decoration: underline;
    line-height: 1.2rem;
}

#quickAddPopBody {
    height: 31em;
    width: 100%;
    overflow-y: auto;
    background-color: #474e59;

    .button-list {
        .btn {
            margin-bottom: 12px;
            margin-left: 0px;
        }
    }
}

.popover {
    max-width: max-content;

    .card {
        width: 100%;
        background-color: #f1f3fa;
        border: 1px solid #dee2e6;

        .card-title {
            color: BLACK;
            width: 100%;
        }

        .card-body {
            .btn-icon {
                width: 100%;
                border-radius: 0.5rem;
            }
            span {
                display: block;
                font-size: 1.3rem;
                line-height: 1.5rem;
            }
        }
    }
}

.popover .card .card-body .btn-icon:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.quickCardBody.card-body {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#quickAddPop .btn-icon i {
    font-size: 4rem;
    line-height: 1rem;
}

.popover .card .card-body .btn-icon {
    padding: 2px 6px;
    border-radius: 0.15rem;
}
.popover .card .card-body .btn-icon:hover {
    opacity: 1 !important;
}
#expandButton {
    border: 1px solid #dee2e6;
    font-family: Nunito, sans-serif;
}

.nineCardContainer .card-body .row,
#searchResults .card-body .row {
    margin: 0;
}

.nineCardContainer .card-body,
#searchResults .card-body {
    padding: 2.443%;
}

#search_text {
    margin-bottom: 0.75rem !important;
}

.nineCardContainer .text-white.card {
    margin-bottom: 0.75rem !important;
}
