//
// right-sidebar.scss
//

.right-bar {
    background-color: $rightbar-bg;
    box-shadow: 0 0 24px 0 rgba($dark, 0.1), 0 1px 0 0 rgba($dark, 0.08);
    display: block;
    position: fixed;
    transition: all 200ms ease-out;
    width: $rightbar-width;
    z-index: 9999;
    float: right !important;
    right: -($rightbar-width + 0.5%);
    top: 0;
    bottom: 0;
    padding-bottom: 80px;

    .rightbar-title {
        background-color: $rightbar-title-bg;
        padding: 27px 25px;
        color: $rightbar-title-color;
    }
    .right-bar-toggle {
        background-color: $rightbar-title-btn-bg;
        height: 24px;
        width: 24px;
        line-height: 22px;
        color: $rightbar-title-btn-color;
        text-align: center;
        border-radius: 50%;
        margin-top: -4px;
    }

    .showRightBarButton {
        margin-left: -40px;
        border: none;
        border-radius: 100px 0px 0px 100px;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        position: absolute;
        bottom: 10px;
        -moz-box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
        padding: 10px 0 10px 10px;
        background-color: #fff;
    }
    .showRightBarButton:hover {
        color: red;
        padding-right: 35px;
        margin-left: -69px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        background-color: #fff;
    }
    .showRightBarButton i {
        font-size: x-large;
    }

    .collapseRightBarButton {
        margin-left: 0px;
        border: none;
        border-radius: 0px 1000px 1000px 0px;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        position: absolute;
        bottom: 10px;
        -moz-box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
        padding: 10px 10px 10px 0px;
        background-color: #fff;
        z-index: 2;
    }
    .collapseRightBarButton:hover {
        color: red;
        padding-left: 35px;
        margin-left: 0px;
        transition: 0.3s;
        background-color: #fff;
    }
    .collapseRightBarButton i {
        font-size: x-large;
    }
}

.rightbar-overlay {
    background-color: $rightbar-overlay-bg;
    opacity: 0.1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 9998;
    transition: all 0.2s ease-out;
}

.right-bar-enabled {
    .right-bar {
        right: 0;
        min-width: 300px;

        .rightbar-content {
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }
    .rightbar-overlay {
        display: block;
    }

    // Content Page
    .content-page {
        margin-right: $rightbar-width;
    }

    //Navbar & Footer
    .navbar-custom,
    .footer {
        right: $rightbar-width;
    }
    .showRightBarButton {
        margin-left: -34px;
    }
}

.right-bar-enabled.fullScreen .right-bar {
    width: calc(100% - 260px);
}

.right-bar-enabled.docked .widgets {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
}

body[data-leftbar-compact-mode='condensed'].right-bar-enabled.fullScreen .right-bar {
    width: calc(100% - 70px);
}

.left-side-menu .app-search {
    padding: 10px 22px 10px 30px;
}
@include media-breakpoint-down(sm) {
    .right-bar {
        // overflow: auto;
        .slimscroll-menu {
            height: auto !important;
        }
    }

    .right-bar-enabled.fullScreen .right-bar {
        width: calc(100% - 0px);
    }
}

@media (max-width: 1024px) {
    .right-bar-enabled.docked .right-bar {
        min-width: 300px;
    }
}

@media (max-width: 768px) {
    .app-search .form-control {
        padding-left: 20px;
        padding-right: 2px;
    }
}
