h1,
h2,
h3,
h4,
h5 {
    font-family: Nunito, sans-serif !important;
}

.app .form-control:focus,
.app form-control:valid:focus {
    box-shadow: 0px 0px 7px 2px rgba(193, 201, 208, 1) !important;
    -webkit-box-shadow: 0px 0px 7px 2px rgba(193, 201, 208, 1) !important;
    -moz-box-shadow: 0px 0px 7px 2px rgba(193, 201, 208, 1) !important;
}

.CustomPageTitle {
    .page-title-box .page-title {
        font-size: 1.3rem;
        margin: 0;
        line-height: 32px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: inherit;
    }
}

.previewSpinnerDiv {
    .status {
        top: 10%;
    }

    .bouncing-loader>div {
        margin: 32px 3px;
        background: #313a46;
        border-radius: 50%;
        animation: bouncing-loader 0.6s infinite alternate;
    }

    .bouncing-loader>div:nth-child(2) {
        animation-delay: 0.2s;
        background: #313a46;
    }

    .bouncing-loader>div:nth-child(3) {
        animation-delay: 0.4s;
        background: #313a46;
    }
}

.previewSpinnerDiv.forTable {
    .status {
        top: 40%;
    }
}

#openWindowButton,
#closeWindowButton {
    display: none;
}

.appMenuSpinnerDiv {
    position: absolute;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0;
    top: 48px;
}

.CustomMaskForIframe .preloader {
    background-color: transparent;
}

.footerDivs {
    display: inline-block;
    margin-right: 10px;
}

.vl {
    border-left: 2px solid #6c757d;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.filterDetailsCont p {
    display: block;
}

.publicFilter {
    margin-top: 7px;
}

button.btn-icon.ruleButtons.btn {
    display: inline-block;
    margin: 0px 6px 0px 0px;
}

.displayNone {
    display: none !important;
}

.rulesBtnContTd {
    min-width: 120px !important;
}

.actionButtonsContainer button {
    margin: 0.25rem;
}

.ui.search>.results {
    max-height: 300px;
    overflow: scroll;
}

.contextMenu {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
    z-index: 1001;

    &--option {
        padding: 6px 50px 5px 10px;
        min-width: 160px;
        cursor: default;
        font-size: 12px;

        &:hover {
            background: linear-gradient(to top, #555, #333);
            color: white;
        }

        &:active {
            color: #e9e9e9;
            background: linear-gradient(to top, #555, #444);
        }

        &__disabled {
            color: #999999;
            pointer-events: none;
        }
    }

    &--separator {
        width: 100%;
        height: 1px;
        background: #cccccc;
        margin: 0 0 0 0;
    }
}

.ui.vertical.menu .item:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.03);
    color: rgba(0, 0, 0, 0.95);
}

.table tr td:nth-child(2) {
    width: 9%;
}

.filterDetailsCont .ui.search .icon.input {
    max-width: 130px;
}

a.noLink,
button.noLink {
    color: currentColor !important;
    cursor: not-allowed !important;
    opacity: 0.5;
    text-decoration: none;
}

.tasks.makeScrolable {
    max-height: 500px;
    overflow-y: scroll;
    cursor: move;
}

.react-select.border-danger {
    border: 1px solid #f45c7c;
    border-radius: 3.5px;
}

.react-select.border-warning {
    border: 1px solid #fff3cd;
    border-radius: 3.5px;
}

.labelDiv label {
    margin-bottom: 0;
    line-height: 2.5;
}

.srv-validation-message {
    color: $red;
}

div.css-26l3qy-menu {
    z-index: 2;
}

input.folder-table-input {
    height: calc(2.25rem + 7px);
}

.upward .contextSubmenuDiv {
    margin-left: 0px !important;
}

.roleActBtnCont .btn {
    margin-right: 10px;
}

.rolemaintanence .custom-control-input~.custom-control-label::before {
    color: $red;
    border-color: $red;
    background-color: $red;
    cursor: pointer;
}

.rolemaintanence .custom-control-input:checked~.custom-control-label::before {
    color: $green;
    border-color: $green;
    background-color: $green;
    cursor: pointer;
}

.rolemaintanence .custom-switch .custom-control-label::after {
    cursor: pointer;
}

.rolemaintanence .custom-accordion-title {
    width: calc(100% - 60px);
    float: left;
    line-height: 16px;
}

.parentFolderSwitch.custom-switch.custom-control {
    margin-top: 10px;
}

a.float-left.folderLinkText {
    margin-top: 8px;
    font-size: 1.3rem;
}

.roleUserTable .react-bootstrap-table table>tbody>tr>td:nth-child(1),
.roleUserTable .react-bootstrap-table table>thead>tr:nth-child(1)>th:nth-child(1) {
    background-color: transparent;
    position: inherit;
    left: 0;
    z-index: 1;
}

.singleFolder.alert {
    background-color: #fff !important;
}

.roleUserTable table#userTable tr {
    background-color: #fff;
}

.labelDiv.roleName {
    max-width: 100px;
}

a.float-left.folderLinkText.singleElement {
    margin-top: 0;
    line-height: 17px;
    margin-right: 1%;
}

.rolemaintanence.maintanenceScreens .custom-accordion-title {
    width: calc(100% - 0px);
    float: left;
    padding-left: 0;
}

#fullscreen-loader-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(14, 14, 14, 0.9);
    z-index: 1002;
}

$spinner-animation-speed: 5s;

.btn.disabled {
    cursor: not-allowed;
}

.menuLoading a {
    opacity: 0.2;
}

#standardTable th label {
    margin: 0px;
}

table#standardTable td {
    padding: 1px 5px;
    vertical-align: middle;
}

#standardTable .btn.btn-light {
    padding: 3px 10px !important;
    width: 100%;
    text-align: center;
}

#standardTable input.filter {
    padding: 5px;
    height: auto;
}

table#standardTable th {
    padding: 0rem 0.2em 0.3rem 0.2rem;
    font-size: 1rem;
    vertical-align: top;
}

.left-side-menu .collapseLeftBarButton {
    border: none;
    border-radius: 100px 0px 0px 100px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    position: fixed;
    bottom: 10px;
    padding: 10px 0 10px 10px;
    background-color: #fff;
    left: 226px;
}

.left-side-menu .collapseLeftBarButton:hover {
    color: red;
    padding-right: 10px;
    margin-left: -10px;
    transition: 0.3s;
    background-color: #fff;
}

body[data-leftbar-compact-mode='condensed'] .wrapper .left-side-menu .collapseLeftBarButton {
    border: none;
    border-radius: 100px 0px 0px 100px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    position: fixed;
    bottom: 10px;
    padding: 10px 0 10px 10px;
    background-color: #fff;
    left: 36px;
}

body[data-leftbar-compact-mode='condensed'] .wrapper .left-side-menu .collapseLeftBarButton:hover {
    color: red;
    padding-right: 10px;
    margin-left: -10px;
    transition: 0.3s;
    background-color: #fff;
}

.collapseLeftBarButton i {
    font-size: x-large;
}

.bouncing-loader>div {
    background: #313a46;
}

.bouncing-loader>div:nth-child(2) {
    background: #313a46;
}

.bouncing-loader>div:nth-child(3) {
    background: #313a46;
}

.appMenuSpinnerDiv .preloader {
    background-color: #313a46;

    .bouncing-loader>div {
        background: #8391a2;
    }

    .bouncing-loader>div:nth-child(2) {
        background: #8391a2;
    }

    .bouncing-loader>div:nth-child(3) {
        background: #8391a2;
    }
}

.refreshTimeUp.input-group {
    border-radius: 4px;
    box-shadow: 0px 0px 2px 1px red;
}

.roleMaintenanceScreenParent {
    padding: 2%;
}

.right-bar-enabled .widgets {
    cursor: -webkit-grabbing;
    cursor: grabbing;
    padding-left: 0;
}

.widgetCheckbox.custom-checkbox.custom-control {
    text-align: right;
    float: revert;
    margin-top: 5px;
}

.react-confirm-alert-overlay {
    z-index: 99999 !important;
}

.custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #28bae6;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.25);
    color: #fff;
}

i.maf-ellipsis {
    font-weight: bold;
    vertical-align: sub;
    font-size: large;
}

.medium-text {
    font-size: medium;
}

div.overflowedwidget {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}

.right-bar-enabled.docked .right-bar .rightbar-content {
    padding: 2%;
}

.tab-pane.active .widgetThumbContainer {
    margin: 0;
}

.row.widgetsHolder {
    margin: 0;
}

.right-bar-enabled .widgets div {
    border-radius: 5px;
}

.navbar-custom {
    min-height: 50px;
}

.nav-user {
    padding: calc(14px / 2) 20px calc(14px / 2) 57px !important;
    min-height: 50px;
}

.content-page {
    padding: 50px 12px 65px;
}

.widgetCard:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.right-bar .rightbar-title {
    padding: 14px 10px;
}

.button-menu-mobile {
    height: 50px;
    line-height: 50px;
}

.folderLinkBtn.btn-group.btn-group-sm {
    display: inline-block;
    float: right;
}

li.side-nav-item {
    position: relative;
}

.folderLinkBtn {
    position: absolute;
    right: 17px;
    top: 8px;
    display: none;
}

.folderLinkBtn button.btn {
    font-size: 18px;
    line-height: 18px;
}

.parentMenu>.folderLinkBtn {
    position: absolute;
    right: 7px;
    top: 9px;
    display: none;
}

.widgetEditBtnCont {
    display: none;
}

.right-bar-enabled.fullScreen .right-bar .widgetEditBtnCont {
    padding: 15px 0px;
    display: block;
}

.right-bar {
    background-color: rgba(49, 58, 70, 0.9);
}

.right-bar-enabled.fullScreen .right-bar .rightbar-content {
    padding: 18px 25px;
}

.right-bar-enabled.fullScreen .right-bar .widgetEditBtnCont button i {
    font-weight: 800;
}

.widgetCard {
    min-width: 171px;
    min-height: 180px;
    border: 1px solid #dee2e6;
    margin-right: 15px;
    border-radius: 5px;
    vertical-align: middle;
    position: relative;
    margin-top: 5px;
    max-width: 200px;
    text-align: center;
}

.widgetCard label,
.widgetCard img,
.widgetCard button {
    display: block;
}

.widgetCard label {
    text-decoration: underline;
    font-size: 1.17rem;
}

img.img-thumbnail {
    margin: 0 auto;
}

.widgetCard button {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    width: 90%;
    margin: auto;
}

.popover.show.bs-popover-auto {
    z-index: 999999;
}

.rolemaintanence .card-header {
    background-color: rgba(49, 58, 70, 0.8);
    padding: 0rem 1.5rem;

    a {
        color: #fff;
    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #9ea5ab;
    border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #9ea5ab;
}

/* width */
.SplittedDivs ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 7px;
}

/* Track */
.SplittedDivs ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.SplittedDivs ::-webkit-scrollbar-thumb {
    background: #9ea5ab;
    border-radius: 7px;
}

/* Handle on hover */
.SplittedDivs ::-webkit-scrollbar-thumb:hover {
    background: #9ea5ab;
}

.table-responsive::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    border-radius: 7px;
}

/* Track */
.table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
    background: #9ea5ab;
    border-radius: 7px;
}

.table-responsive::-webkit-scrollbar-thumb:horizontal {
    background: #9ea5ab;
    border-radius: 7px;
}

/* Handle on hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
    background: #9ea5ab;
}

.text-decoration-underline {
    text-decoration: underline;
}

.widgetsHolder .card-body {
    padding: 0.6rem;
}

.mainWidgetContainer {
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.mainWidgetContainer table tr a {
    text-decoration: underline;
}

.nav-user .account-user-avatar i.mdi.mdi-account-circle {
    font-size: 35px !important;
    line-height: 29px;
    color: #313a46;
}

img.widgetImg.img-thumbnail {
    border: 0;
    padding: 0;
}

.navbar-custom {
    border-bottom: 1px solid #dfe4eb;
}

.nav-user {
    padding: calc(14px / 2) 20px calc(14px / 2) 26px !important;
}

.profileInfo {
    text-align: left;

    .name {
        color: #fff;
    }

    .email {}

    .lastLogin {}
}

.lateJobCard p {
    margin: 0;
}

.lateJobCard.row {
    margin: 0;
    border: 2px solid #dfe4eb;
    margin-bottom: 5px;
}

.lateJobCard.row p.lateTime {
    font-size: 1.2rem;
}

.lateJobCard a {
    text-decoration: underline;
    font-weight: bold;
    font-size: 1rem;
}

.left-side-menu {
    padding-top: 0px;
}

body[data-leftbar-compact-mode='condensed'] .wrapper .left-side-menu {
    padding-top: 50px;
    z-index: 1050;
}

.folderLinkBtn.btn-group:hover {
    background-color: #21212147;
    border-radius: 50%;
}

p.profileInfo {
    padding: 0px;
}

.mytooltip .tooltip-inner {
    min-width: max-content;
}

.tableActions.justify-content-between.row {
    display: inline-block;
    float: right;
    width: 100%;
}

.navbar-custom .topbar-right-menu .nav-link {
    padding: calc(10px / 2) 10px calc(10px / 2) 10px !important;
    background-color: transparent;
    margin-left: 0;
}

.loadingText {
    font-weight: bold;
    padding: 2px;
    font-family: 'Nunito', sans-serif !important;
}

#contextMenu,
.ui.menu {
    font-family: 'Nunito', sans-serif !important;
}

.ui.menu .ui.dropdown.item .menu .item:not(.filtered),
.ui.dropdown .menu>.item {
    padding: 0.9286rem 1.142857rem !important;
}

.ui.dropdown .menu .active.item {
    font-weight: 400;
}

.ui.dropdown .menu>.item.customSubMenu.loading {
    padding: 12px 16px !important;
}

.preloader {
    z-index: 10001;
}

.previewSpinnerDiv.forTable {
    z-index: 2;
}

.account-user-name {
    line-height: 28px;
}

.btn {
    padding: 0.5rem 0.65rem;
}

#iframeContainer {
    min-height: 100vh;
    height: 100%;
}

body[data-leftbar-compact-mode='condensed'] .wrapper .left-side-menu .folderLinkBtn {
    right: 4px;
    top: 15px;
}

.SplittedDiv.SplittedDivForPane {
    height: 100%;
}

p.onlyCondensed {
    display: none;
}

body[data-leftbar-compact-mode='condensed'] .firstLevel p.onlyCondensed {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    white-space: normal;
    width: 90%;
    font-size: 0.7rem;
    margin: auto;
    display: block;
    line-height: 1.2em;
}

body[data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover .side-nav-link p.onlyCondensed {
    display: none;
}

button#AdvanceSearchToggle {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.card.column-card {
    border: 1px solid gray;
    margin: 0.5rem;
    padding: 10px;
    cursor: grabbing;
}

.left-side-menu .collapseLeftBarButton,
.right-bar .showRightBarButton,
.right-bar .collapseRightBarButton {
    color: white !important;
    background-color: #727cf5 !important;
    box-shadow: none !important;
}

body[data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item .side-sub-nav-link span {
    white-space: normal;
}

.detailsViewCard {
    padding: 0;
}

.quickAddHeader {
    background-color: $gray-900;
    color: #ffffff;
    border-bottom: $gray-900;
}

.side-nav .side-nav-link {
    font-size: 1.1rem;
}

.side-nav-forth-level li a,
.side-nav-second-level li a,
.side-nav-third-level li a {
    font-size: 0.99rem;
}

#godSearch {
    border: 1px solid #cdd0d3;
    color: #6c757d;
}

#loadToolbarButton.folderLinkText {
    color: #727cf5;
    float: left !important;
    position: relative;
    left: 0;
}

#loadToolbarButton {
    background-color: #ffffff00;
    position: absolute;
    left: 78px;
    border: none;
    border-bottom-left-radius: 50%;
    color: white;
}

#loadToolbarButton:hover {
    color: black;
    background-color: white;
    transition: 0.2s;
}

.folderSummaryWidget .summaryPilCol {
    text-align: center;
}

button.summaryPil.btn {
    width: 100%;
    cursor: text !important;
}

.widgetPreviewDiv {
    min-height: 230px;
}

.widgetPreviewDiv>div {
    width: 60%;
    margin: auto;
    border: 1px solid #666;
}

.widgetModalBody hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 2px solid #ccc;
}

.rightbar-content .folderPieWidget {
    max-height: 500px;
    overflow: scroll;
}

.pieChartDiv {
    max-width: 50%;
    margin: auto;
}

.widgetsHolder .pieChartDiv {
    max-width: 500px;
    margin: auto;
}

#folder-maintenance .pieChartDiv {
    max-width: 100%;
    margin: auto;
}

.widgetTable.table thead th {
    vertical-align: middle;
}

button#addToolbarButton {
    color: #fff;
    padding: 0px;
    margin: 0;
    margin-top: -6px;
    font-size: 18px;
}

.widgetPreviewDiv .card .card-body,
.folderSummaryWidget.card .card-body {
    padding: 0.6rem;
    padding-bottom: 0.3rem;
}

.summaryPilCol label {
    margin-bottom: 0;
}

.widgetListTable tr td {
    vertical-align: middle;
}

// Media should placed bottom of the file
@media (max-width: 1024px) {
    .app-search span.search-icon {
        left: 0px;
    }

    .appMenuSpinnerDiv {
        top: 110px;
    }

    .filterDetailsCont .ui.search .icon.input {
        max-width: 100px;
    }

    .left-side-menu .collapseLeftBarButton {
        display: none;
    }

    .allActionContainer {
        padding-left: 0;
    }
}

@media (max-width: 768px) {
    .content-page {
        padding-bottom: 100px;
    }

    .topButtonContainer .tableActions .refreshInputs {
        max-width: 110px;
        padding: 0;
    }

    .topButtonContainer .tableActions .filterActionSlt {
        min-width: 180px;
        max-width: 205px;
        padding: 0;
    }

    .topButtonContainer .tableActions .quickSearchTxt {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        max-width: 127px;
    }

    #AdvanceSearchToggle {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }

    .advCntrlBtnContinaer {
        max-width: 170px;
        padding: 0;
        margin-top: 10px;
    }

    .widgetPreviewDiv>div {
        width: 100%;
        margin: auto;
    }
}

// Size breakpoint support (https://stackoverflow.com/a/47762042)
@each $breakpoint in map-keys($grid-breakpoints) {

    @each $size,
    $length in $sizes {
        @include media-breakpoint-up($breakpoint) {
            .w-#{$breakpoint}-#{$size} {
                width: $length !important;
            }
        }
    }
}

@media (max-width: 425px) {
    .topButtonContainer .tableActions .filterActionSlt {
        min-width: 170px;
        max-width: 100%;
        padding: 0;
        margin-top: 10px;
    }

    .titleContainer {
        display: none;
    }

    div#SplittedDivTable {
        margin-left: 0;
        margin-right: 0;
    }

    .topButtonContainer .tableActions .downLoadCsv {
        padding-right: 0;
        padding-left: 0;
        max-width: 40px;
        margin-top: 10px;
    }

    .topButtonContainer .tableActions .quickSearchTxt {
        padding-right: 0;
        max-width: 100%;
    }

    .content-page .content .container-fluid {
        padding: 0;
    }

    .content-page .content .container-fluid .allActionContaine {
        padding: 0;
    }

    .advCntrlBtnContinaer {
        max-width: 100% !important;
    }

    .topButtonContainer .tableActions .refreshInputs {
        margin-top: 10px;
        max-width: 100%;
    }
}

.compactView {
    #standardTable input.filter {
        padding: 3px;
        font-size: 10px;
    }

    #standardTable button.rowActionButton.btn {
        margin: 0px !important;
        padding: 0px !important;
        line-height: 1
    }

    table#standardTable {
        font-size: 10px !important;
        padding: 1px;
    }

    table#standardTable thead th {
        padding: 1px;
        font-size: 10px;
    }
}

.hardToView {
    #standardTable input.filter {
        font-weight: bold;
    }

    #standardTable button.rowActionButton.btn,
    table#standardTable td {
        font-weight: bold;
    }

    table#standardTable {
        font-weight: bold;
    }

    table#standardTable thead th {
        font-weight: bold;
    }
}


.ui.menu .ui.dropdown.item .menu .item.customSubMenu.activeSubMenu {
    border: 2px solid red;
}

.ui.vertical.menu .dropdown.item:not(.upward) .menu {
    top: 0;
    overflow-y: scroll;
}

#contextMenu:focus {
    outline: none;
}

table.batchSelect td {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.labelCol {
    display: flex;

    label {
        margin: auto;
        width: 100%;
    }
}

button.keyBindBtn {
    min-width: 140px;
    width: 65%;
}

.shortcutSetupModal .custom-accordion-title {

    background-color: rgba(49, 58, 70, 0.8);
    color: #fff;
}

input#shortcutSearch_text {
    max-width: 200px;
    width: 28%;
}

#versioninfotext {
    float: right;
    line-height: 3;
}

.fallBack-icon::before {
    content: '*';
}

.SplittedDivs>div:nth-child(1) {
    overflow-y: hidden !important;
}

.tableFixedHeader,
.tableFixedHeader table {
    height: 100%;
    overflow-y: scroll;
}

.tableFixedHeader thead th {
    top: 0px;
    position: sticky;
    background-color: white;
    z-index: 99;
}

.tableFixedHeader tbody tr td {
    z-index: 0;
    position: relative;
}

.tableFixedHeader tbody tr td:nth-child(1) {
    z-index: 1;
}

.tableFixedHeader thead>tr:nth-child(1)>th:nth-child(1) {
    z-index: 999 !important;
    background-color: #f7f7f7 !important;
}

.tableFixedHeader th::before {
    position: absolute;
    content: '';
    top: -1px;
    left: -2px;
    width: calc(100% + 2px);
    height: 2px;
    ;
    background-color: rgb(238, 242, 247);
}

.tableFixedHeader th::after {
    position: absolute;
    content: '';
    bottom: -1px;
    left: -2px;
    width: calc(100% + 2px);
    height: 2px;
    ;
    background-color: rgb(238, 242, 247);
}

.editCustomSql {
    float: right;
    position: absolute;
    top: 0;
    right: 0;
}