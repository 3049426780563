div[data-type='SplitPane'] > div:nth-child(odd) {
    overflow-y: auto;
    overflow-x: hidden;
}
.sc-bwzfXH.hEACtv {
    margin-bottom: 10px;
    margin-top: 10px;
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.sc-bwzfXH.hEACtv:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.sc-bwzfXH.hEACtv {
    height: 15px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.page-item.active .page-link {
    z-index: 1;
}

#panecontainer.previewDetached {
    height: 100%;
    display: block;
}

#panecontainer.previewDetached .sc-bwzfXH.hEACtv.sc-bdVaJa.cjjWdp {
    display: none;
}

#panecontainer.previewDetached div[data-type='SplitPane'] > div:nth-child(3) {
    display: none;
}

#panecontainer.previewDetached .css-glamorous-div--hvdu2p {
    min-height: 100vh;
}

#panecontainer {
    min-height: 1000px;
}
