//
// tables.scss
//

//Table centered (Custom)
.table-centered {

    th,
    td {
        vertical-align: middle !important;
    }
}

// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }

    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }
}

// Tables fluid
.table-nowrap {

    th,
    td {
        white-space: nowrap;
    }
}

.react-bootstrap-table table#standardTable>tbody>tr>td:nth-child(1) {
    background-color: #f7f7f7d6;
}

.react-bootstrap-table table#standardTable>thead>tr:nth-child(1)>th:nth-child(1) {
    background-color: #313a46;
}

.react-bootstrap-table table#standardTable>tbody>tr>td:nth-child(1),
.react-bootstrap-table table#standardTable>thead>tr:nth-child(1)>th:nth-child(1) {
    background-color: #f7f7f7d6;
}

.react-bootstrap-table table#standardTable>tbody>tr>td:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    width: 50px;
}

.react-bootstrap-table table#standardTable>thead>tr:nth-child(1)>th:nth-child(1) {
    left: 0;
    z-index: 1;
    width: 50px;
    position: sticky;
    position: -webkit-sticky;
}

.react-bootstrap-table table#standardTable>tbody>tr.rowZindexControl td {
    z-index: 2;
}

.react-bootstrap-table table#standardTable>thead>tr.rowZindexControl th {
    z-index: 3 !important;
}

tr.focused {
    background-color: #c8e6c9 !important;
    box-shadow: 0px 0px 5px 0px #000000;
    color: #000 !important;
}

tr.custom-row-class {
    cursor: pointer;
}

.table thead th {
    vertical-align: middle;
}

.react-bootstrap-table table#activeDriverWidgetTable>tbody>tr>td:nth-child(1) {
    background-color: inherit;
}

.serverSidePageCont {
    tbody tr {
        transition-duration: 0.3s;
        cursor: pointer;

        a {
            color: $gray-800;

            &:hover {
                color: $gray-800;
            }
        }

        .tablerow-action-icons {
            opacity: 1;
            position: absolute;
            right: -200;
            top: 0;
            bottom: 0;
            background-color: $gray-100;

            ul {
                display: flex;
                transition: all 0.5s;

                li {
                    margin: 0 10px;
                }

                .tablerow-action-icons-item {
                    font-size: 20px;
                    transition: all 0.5s;

                    &:hover {
                        color: $danger;
                    }
                }
            }
        }

        &:hover {
            background: $gray-100;
            transition-duration: 0.05s;

            .tablerow-action-icons {
                opacity: 1;
                height: 33px;
                z-index: 2;
                transition: all 10.5s;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;

                ul {
                    transition: all 0.5s;
                }
            }
        }
    }
}

label.tableActionsLabel {
    line-height: 2.5;
    margin: 0;
}

table#standardTable td {
    padding: 0.3rem 0.95em;
    vertical-align: middle;
}

tr.selection-row {
    background-color: #727cf5 !important;
    color: #fff !important;
}

#standardTable .hideColumn {
    display: none;
}

#widgetTable .hideColumn {
    display: none;
}

@media (max-width: 648px) {
    .serverSidePageCont tbody tr .tablerow-action-icons ul {
        display: block;
        position: initial;
        transition: all 0.5s;
    }

    table#standardTable td {
        padding: 0.95em;
    }
}

#standardTable .sortedColumn {
    color: #17191b;
}

#alertContainer {
    display: none;
}

#alertContainer.alert-success,
#alertContainer.alert-danger {
    display: block;
}

.filter-label {
    display: block;
}

html {
    scroll-behavior: smooth;
}

.previewSpinnerDiv {
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    text-align: center;
}

.item:focus {
    background-color: red;
}

.rc-menu.rc-menu--open.rc-menu--animation.rc-menu--dir-right {
    max-height: 200px;
    overflow-y: scroll;
}

.topButtonContainer {
    margin-top: 10px;
}

.activeDropDown .table-responsive {
    overflow-x: unset;
}

table#standardTable td.react-bs-table-no-data {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
}